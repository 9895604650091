<template>
  <div class="PracticePage rr left" ref="mousePage">
    <div class="practice-controller">
      <practice-view />
    </div>
    <div class="practice-info">
      <practice-header />
      <practice-content />
    </div>
  </div>
  <a-modal
    :visible="!handleTimeController"
    :footer="null"
    :closable="false"
    width="660px"
  >
    <div class="atModel">
      <i class="fail bg"></i>
      <p>您已暂停操作，点击后可继续操作！</p>
      <button @click="atStart">继续操作</button>
    </div>
  </a-modal>
  <a-modal :visible="mouseStopController" :footer="null" :closable="false" width="660px">
    <div class="atModel">
      <i class="fail bg"></i>
      <p>300秒无操作，已自动暂停，点击可继续操作！</p>
      <button @click="atStart">继续操作</button>
    </div>
  </a-modal>
  <a-modal :visible="isEnd" :footer="null" :closable="false" width="660px">
    <div class="atModel">
      <i class="success bg"></i>
      <p>当前病例已完成</p>
      <div class="float" style="padding: 0 80px">
        <button @click="toBack">返回首页</button>
        <button @click="closeIsEndModel">继续查看</button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import practiceHeader from "./modules/practiceHeader";
import practiceContent from "./modules/practiceContent";
import practiceView from "../Controller/Index";
import { answer } from "@/mixins";
export default {
  name: "practice",
  mixins: [answer],
  components: {
    practiceHeader,
    practiceContent,
    practiceView,
  },
  data() {
    return {
      switchTime: null,
      mouseStopController: false,
      lastTime: new Date().getTime(),
      firstTime: new Date().getTime(),
      indulge: false,
    };
  },
  async created() {
    //设置操作初始时间
    this.setHandleTime();
    //获取开始数据
    this.getData();
    //获取药物列表
    this.getDrugData();
    this.getDrug1Data();
    //获取步骤列表
    this.getStepData();
    //获取病人位置
    this.getPositionData();
    //获取控制温度
    this.getTemperature();
    //获取科室
    this.getDepartment();

    await this.$http.get(this.$interface.dictList + "/componet_object");
  },
  mounted() {
    this.mouseStop();
    localStorage.setItem("Times", JSON.stringify(0));
    localStorage.setItem("Stops", JSON.stringify("NO"));
    localStorage.setItem("StopsT", JSON.stringify("NO"));
  },
  unmounted() {
    this.clearHandleTime();
    this.clearInfo();
    this.clearController();
    if (this.switchTime) {
      clearInterval(this.switchTime);
    }
  },
  methods: {
    ...mapActions({
      setHandleTime: "answer/setHandleTime",
      stopHandleTime: "answer/stopHandleTime",
      clearHandleTime: "answer/clearHandleTime",
      setStepType: "exma/setStepType",
      setExmaData: "exma/setExmaData",
      setDrugData: "exma/setDrugData",
      setDrug1Data: "exma/setDrug1Data",
      setPatientPositionDict: "exma/setPatientPositionDict",
      setControlTemperature: "exma/setControlTemperature",
      setDepartment: "exma/setDepartment",
      clearInfo: "exma/clearInfo",
      clearController: "controller/clearController",
      closeIsEndModel: "exma/closeIsEndModel",
    }),
    async getData() {
      try {
        let res = await this.$http.post(this.$interface.examStart, {
          caseId: this.$route.query.caseId,
          missionId: this.$route.query.missionId,
        });
        let patientDataArrayT = [];

        localStorage.setItem(
          "patientDataArray",
          JSON.stringify(res.data.data.stepInfo.patientDataArray)
        );
        let timessT = -1;
        let time2 = setInterval(() => {
          patientDataArrayT = [];
          timessT += 1;
          let arrs = JSON.parse(localStorage.getItem("patientDataArray"));
          if (
            timessT >= res.data.data.stepInfo.patientDataArray.length ||
            JSON.parse(localStorage.getItem("StopsT")) === "Yes"
          ) {
            clearInterval(time2);
            return;
            timess -= 1;
          }
          patientDataArrayT.push(arrs[timessT]);
          localStorage.setItem("patientDataArrayT", JSON.stringify(patientDataArrayT));
          localStorage.setItem("timessT", JSON.stringify(timessT));
        }, 1000);

        localStorage.setItem(
          "checkDataArray",
          JSON.stringify(res.data.data.stepInfo.checkDataArray)
        );
        let timess = 0;
        let time1 = setInterval(() => {
          if (
            JSON.parse(localStorage.getItem("Times")) &&
            JSON.parse(localStorage.getItem("Times")) != timess
          ) {
            clearInterval(time1);
          }
          timess += 1;
          if (
            timess >= res.data.data.stepInfo.checkDataArray.length ||
            JSON.parse(localStorage.getItem("Stops")) == "Yes" ||
            JSON.parse(localStorage.getItem("StopsT")) == "Yes"
          ) {
            clearInterval(time1);
            timess -= 1;
          }
          console.log(timess, "TimesTimes");
          localStorage.setItem("Times", JSON.stringify(timess));
        }, 1000);
        this.setExmaData(res.data.data || {});
      } catch (err) {}
    },
    async getDrugData() {
      if (this.drugData.length != 0) return;
      try {
        let drug = await this.$http.get(this.$interface.drugList + "?type=2");
        this.setDrugData(drug.data.data || []);
      } catch (err) {}
    },
    async getDrug1Data() {
      if (this.drug1Data.length != 0) return;
      try {
        let drug = await this.$http.get(this.$interface.drugList + "?type=1");
        this.setDrug1Data(drug.data.data || []);
      } catch (err) {}
    },
    async getStepData() {
      if (this.stepType.length != 0) return;
      try {
        let stepType = await this.$http.get(
          this.$interface.dictList + "/operation_phase"
        );
        this.setStepType(stepType.data.data || []);
      } catch (err) {}
    },
    async getPositionData() {
      if (this.patientPositionDict.length != 0) return;
      try {
        let res = await this.$http.get(
          this.$interface.dictList + "/patient_position_dict"
        );
        this.setPatientPositionDict(res.data.data || []);
      } catch (err) {}
    },
    async getTemperature() {
      if (this.controlTemperature.length != 0) return;
      try {
        let res = await this.$http.get(this.$interface.dictList + "/control_temperature");
        this.setControlTemperature(res.data.data || []);
      } catch (err) {}
    },
    async getDepartment() {
      if (this.department.length != 0) return;
      try {
        let res = await this.$http.get(this.$interface.dictList + "/laboratory_studies");
        this.setDepartment(res.data.data || []);
      } catch (err) {}
    },

    atStart() {
      this.firstTime = new Date().getTime();
      this.lastTime = new Date().getTime();
      this.indulge = true;
      this.setHandleTime();
      this.mouseStopController = false;
    },
    toBack() {
      this.$router.replace("/");
    },
    mouseStop() {
      //初始化
      let diff = 300000; //未操作触发间隔
      this.indulge = true; //阀门
      this.firstTime = new Date().getTime();
      this.lastTime = new Date().getTime();
      //停止
      this.$refs.mousePage.onmousemove = () => {
        this.indulge = true;
        this.firstTime = new Date().getTime();
      };
      //再启动
      this.switchTime = setInterval(() => {
        this.lastTime = new Date().getTime();
        if (
          this.lastTime - this.firstTime > diff &&
          this.indulge &&
          this.handleTimeController
        ) {
          this.indulge = false;
          this.mouseStopController = true;
          this.stopHandleTime();
        }
      }, 1000);
    },
  },
  watch: {
    layoutStyle(val) {
      let root = document.querySelector(":root");
      if (val == true) {
        root.style.setProperty("--text-color", "#2A3866");
        root.style.setProperty("--bg-color", "#FBFCFF");
        root.style.setProperty("--info-bg", "#F6F8FF");
        root.style.setProperty("--log-bg", "#FFFFFF");
        root.style.setProperty("--text-yellow", "rgba(42, 56, 102, 1)");
        root.style.setProperty("--text-blue", "rgba(42, 56, 102, 1)");
        root.style.setProperty("--text-green", "rgba(42, 56, 102, 1)");
        root.style.setProperty("--blue-yellow", "rgba(16, 87, 229, 1)");
        root.style.setProperty("--blue-blue", "rgba(16, 87, 229, 1)");
        root.style.setProperty("--blue-green", "rgba(16, 87, 229, 1)");
      } else {
        root.style.setProperty("--text-color", "#FFFFFF");
        root.style.setProperty("--bg-color", "#09163D");
        root.style.setProperty("--info-bg", "#09163D");
        root.style.setProperty("--log-bg", "#EDF1FF");
        root.style.setProperty("--text-yellow", "rgba(242, 253, 54, 1)");
        root.style.setProperty("--text-blue", "rgba(28, 168, 252, 1)");
        root.style.setProperty("--text-green", "rgba(43, 254, 180, 1)");
        root.style.setProperty("--blue-yellow", "rgba(242, 253, 54, 1)");
        root.style.setProperty("--blue-blue", "rgba(28, 168, 252, 1)");
        root.style.setProperty("--blue-green", "rgba(43, 254, 180, 1)");
      }
    },
  },
  computed: {
    ...mapGetters([
      "layoutStyle",
      "getHandleTimeAt",
      "handleTimeController",
      "patientPositionDict",
      "controlTemperature",
      "isEnd",
      "department",
      "drugData",
      "stepType",
      "drug1Data",
    ]),
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>
