<template>
  <div class="practiveHeaderPage">
    <div class="step-view">
      <div class="step-info float">
        <h4>分数：{{ score }}/{{ totalScore }}</h4>
        <div class="step-info-tip right">
          <p class="left" v-for="(item, index) in stepType" :key="index">
            <i
              :class="
                item.code == 'before'
                  ? 'orange'
                  : item.code == 'middle'
                  ? 'green'
                  : 'blue'
              "
            ></i
            >{{ item.name }}
          </p>
          <!-- <p class="left"><i class="green"></i>诱导</p>
          <p class="left"><i class="blue"></i>维持</p> -->
        </div>
      </div>
      <div class="step-content">
        <div class="step-content-view right" :style="caseStepsStyle.stepStyle">
          <div class="step-content-view-box right">
            <div
              class="step-content-view-item"
              :class="[
                item.check == true ? 'step-content-view-item-active' : '',
                caseStepsStyle.stepIndex - 1 == index ? 'stepButton' : '',
              ]"
              :style="{ width: caseStepsStyle.stepItem + '%' }"
              v-for="(item, index) in caseSteps"
              :key="index"
            ></div>
          </div>
        </div>
        <div class="step-content-text left">
          <p
            class="one_line"
            :class="[item.check == true ? 'step-content-text-active' : '']"
            :style="{ width: caseStepsStyle.stepItem + '%' }"
            v-for="(item, index) in caseSteps"
            :key="index"
          >
            <a-tooltip
              placement="left"
              :title="item.stepName"
              :get-popup-container="getPopupContainer"
            >
              {{ item.stepName }}
            </a-tooltip>
          </p>
        </div>
      </div>
    </div>
    <div class="controller-view all">
      <button class="stop left" @click="atStop"><i class="bg"></i>暂停</button>

      <a-popconfirm
        title="请再次确认是否返回首页"
        ok-text="是"
        cancel-text="否"
        @confirm="goHome"
      >
        <button class="home left"><i class="bg"></i>首页</button>
      </a-popconfirm>
      <a-switch :checked="layoutStyle" @click="layoutChange" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      checked: true,
      getPopupContainer: (trigger) => {
        return trigger.parentElement;
      },
    };
  },
  methods: {
    ...mapActions({
      setLayout: "theme/setLayoutStyle",
      stopHandleTime: "answer/stopHandleTime",
    }),
    goHome() {
      this.$router.replace("/");
    },
    atStop() {
      this.stopHandleTime();
    },
    layoutChange() {
      this.setLayout("layoutStyle");
    },
  },
  computed: {
    ...mapGetters([
      "layoutStyle",
      "stepType",
      "caseSteps",
      "totalScore",
      "score",
    ]),
    caseStepsStyle: function () {
      let caseSteps = this.caseSteps || [];
      let _b = caseSteps.filter((item) => item.operationPhase == "before");
      let _n = caseSteps.filter((item) => item.operationPhase == "middle");
      let _a = caseSteps.filter((item) => item.operationPhase == "ops");
      let _index = caseSteps.filter((item) => item.check == true);
      let _allLength = caseSteps.length || 0;
      if (_allLength == 0)
        return {
          stepItem: 0,
          stepStyle: {},
          stepIndex: 0,
        };
      let stepBackground = `linear-gradient(90deg, #FFDF2C 0%, #26E22D ${(
            ((_b.length + _n.length) / _allLength) *
            100
          ).toFixed(2)}%, #3B65F7 100%)`;
      //没有术前
      if(_b.length == 0){
        stepBackground = `linear-gradient(90deg, #26E22D 0%, #3B65F7 100%)`;
      }
      //没有诱导
      if(_n.length == 0){
         stepBackground = `linear-gradient(90deg, #FFDF2C 0%, #3B65F7 100%)`;
      }
      //只有术前
      if(_n.length == 0 && _a.length == 0){
         stepBackground = `linear-gradient(90deg, #26E22D 0%, #61f164 100%)`;
      }
      //只有诱导
      if(_b.length == 0 && _a.length == 0){
         stepBackground = `linear-gradient(90deg, #61f164 0%, #26E22D 100%)`;
      }
      //只有维护
      if(_b.length == 0 && _n.length == 0){
         stepBackground = `linear-gradient(90deg, #26E22D 0%, #3B65F7 100%)`;
      }
      return {
        stepItem: ((1 / _allLength) * 100).toFixed(2),
        stepStyle: {
          background: stepBackground,
        },
        stepIndex: _index.length,
      };
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>